import { classNames } from '~/utils/class-names'
import type { Colors } from '~/utils/colors'

export type ShadowIconProps = {
  size?: number | string
  color?: Colors
  children?: React.ReactNode
}

export const ShadowIcon = ({ size = 48, color = 'blue', ...props }: ShadowIconProps) => {
  return (
    <span
      className={classNames('flex items-center justify-center rounded-full border-8 p-[6px]', {
        'border-green-50 bg-green-200/70': color === 'green',
        'border-red-50 bg-red-200/70': color === 'red',
        'border-brand-50 bg-brand-200/70': color === 'blue',
      })}
      style={{ width: size, height: size }}
      {...props}
    />
  )
}
