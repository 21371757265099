import { AlertTriangle } from 'lucide-react'

import { ShadowIcon } from '~/components/ShadowIcon'
import { classNames } from '~/utils/class-names'

type NotFoundProps = {
  title?: string
  subtitle?: string | React.ReactNode
  withShadow?: boolean
}

export function NotFound({
  title = 'Oops!',
  subtitle = 'Did not match any results.',
  withShadow = false,
}: NotFoundProps) {
  return (
    <div
      className={classNames('my-5 overflow-hidden rounded-md transition-all', {
        'border shadow-sm': !!withShadow,
      })}
    >
      <div className="m-9 flex flex-col items-center justify-center gap-4">
        <ShadowIcon size={58}>
          <AlertTriangle />
        </ShadowIcon>
        <h3 className="text-2xl font-semibold">{title}</h3>
        <p className="text-text-secondary">{subtitle}</p>
      </div>
    </div>
  )
}
